<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <v-row>
      <v-col class="py-0" cols="12">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-text-field
                  label="Tìm kiếm"
                  placeholder="Nhập để tìm kiếm theo MSSV hoặc tên"
                  clearable
                  outlined
                  hide-details
                  v-model="params.search"
                  dense
                  @keyup.enter="getResult(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-autocomplete
                  label="Môn học"
                  placeholder="Chọn môn học"
                  clearable
                  outlined
                  hide-details
                  dense
                  :items="subjects"
                  item-text="fullName"
                  item-value="id"
                  @change="getResult(1)"
                  v-model="params.subjectId"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-autocomplete
                  label="Mã lớp"
                  placeholder="Chọn mã lớp"
                  clearable
                  outlined
                  hide-details
                  dense
                  :items="classIds"
                  item-text="class_id"
                  item-value="class_id"
                  v-model="params.classId"
                  @change="getResult(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="3" class="pb-0">
                <v-autocomplete
                  label="Mã lớp"
                  placeholder="Chọn nhóm"
                  clearable
                  outlined
                  hide-details
                  dense
                  :items="studyGroupNames"
                  item-text="study_group_name"
                  item-value="study_group_name"
                  v-model="params.studyGroupName"
                  @change="getResult(1)"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col> </v-row
      ></v-col>
      <v-col class="py-0" cols="12">
        <v-data-table
          disable-sort
          :headers="headers"
          :items="result"
          disable-pagination
          fixed-header
          calculate-widths
          height="calc(100vh - 254px)"
          hide-default-footer
          :server-items-length="1"
          disable-filtering
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar dense class="custom-toolbar" flat>
              <v-toolbar-title v-if="examination" style="font-size: 15px"
                >Kíp thi: {{ examination.name }} - Tổng:
                {{ pagination.total }}</v-toolbar-title
              >
              <v-spacer></v-spacer>

              <v-btn
                small
                color="green"
                v-if="examination"
                :loading="examination.isCalculated === false"
                @click="dialog = true"
                >Tính kết quả thi</v-btn
              >
              <v-btn
                small
                color="green"
                class="mr-0"
                v-if="examination"
                @click="exportExcel"
                >Xuất excel</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.result="{ item }">
            {{
              Math[item.exam.meta.questions_count == 30 ? "round" : "ceil"](
                ((item.status.score * 10) / item.exam.meta.questions_count) * 2
              ) / 2
            }}
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="py-0">
        <Pagination
          :length="pagination.lastPage"
          :params="params"
          @handle-change-page="getResult"
          @handle-change-per-page="getResult(1)"
      /></v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>Tính điểm</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                :items="originalExams"
                v-model="bonusQuestion.originalExamId"
                item-text="name"
                item-value="id"
                label="Đề gốc"
                hide-details
                dense
                @change="onOriginalExamIdChange"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="questions"
                v-model="bonusQuestion.originalQuestionId"
                item-text="order"
                item-value="id"
                label="Câu hỏi"
                hide-details
                dense
              ></v-autocomplete
            ></v-col>
            <v-col cols="4"
              ><v-btn small color="blue" @click="addBonusQuestion"
                >Thêm câu thưởng</v-btn
              ></v-col
            >
            <v-col cols="12">
              <v-data-table
                disable-filtering
                disable-sort
                :items="bonusQuestions"
                disable-pagination
                hide-default-footer
                :headers="[
                  {
                    text: 'Đề',
                    value: 'originalExamId',
                  },
                  {
                    text: 'Câu',
                    value: 'originalQuestionId',
                  },
                  {
                    text: '',
                    value: 'actions',
                  },
                ]"
              >
                <template v-slot:item.originalExamId="{ item }">
                  {{
                    originalExams.find((o) => o.id === item.originalExamId).name
                  }}
                </template>
                <template v-slot:item.originalQuestionId="{ item }">
                  {{
                    originalExams
                      .find((o) => o.id === item.originalExamId)
                      .questions.find((q) => q.id === item.originalQuestionId)
                      .order
                  }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    @click="deleteBonusQuestion(item.originalQuestionId)"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="calculate"
            :loading="loading"
            small
            class="d-block mx-auto"
            color="blue"
            >Tính điểm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  getMetadata,
  getResult,
  calculate,
  getOriginalExams,
} from "@/api/examination";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  watch: {
    dialog(val) {
      if (val) {
        this.bonusQuestions = [];
        this.bonusQuestion = {
          originalExamId: null,
          originalQuestionId: null,
        };
      }
    },
  },
  data() {
    return {
      dialog: false,
      originalExams: [],
      questions: [],
      loading: false,
      bonusQuestion: {
        originalExamId: null,
        originalQuestionId: null,
      },
      bonusQuestions: [],
      examination: null,
      subjects: [],
      classIds: [],
      studyGroupNames: [],
      result: [],
      pagination: {
        last_page: 1,
        total: 0,
      },
      params: {
        search: "",
        page: 1,
        perPage: 20,
        sortBy: "student_code",
        subjectId: "",
        classId: "",
        studyGroupName: "",
        result: true,
      },
      headers: [
        {
          text: "Mã học phần",
          value: "examinationSubject.subject.code",
        },
        {
          text: "Tên học phần",
          value: "examinationSubject.subject.name",
        },
        {
          text: "Mã sinh viên",
          value: "studentCode",
        },
        {
          text: "Tên sinh viên",
          value: "studentName",
        },
        {
          text: "Mã lớp",
          value: "classId",
        },
        {
          text: "Nhóm",
          value: "studyGroupName",
        },
        {
          text: "Số thứ tự",
          value: "order",
        },
        {
          text: "Đề số",
          value: "exam.code",
        },
        {
          text: "Số câu làm",
          value: "status.currentQuestion",
        },
        {
          text: "Số câu đúng",
          value: "status.score",
        },
        {
          text: "Điểm",
          value: "result",
        },
      ],
    };
  },
  methods: {
    deleteBonusQuestion(originalQuestionId) {
      const idx = this.bonusQuestions.findIndex(
        (item) => item.originalQuestionId === originalQuestionId
      );
      this.bonusQuestions.splice(idx, 1);
    },
    onOriginalExamIdChange(e) {
      if (e) {
        this.questions = this.originalExams.find(
          (item) => item.id === e
        ).questions;
        this.bonusQuestion.originalQuestionId = null;
      }
    },
    addBonusQuestion() {
      if (
        !this.bonusQuestion.originalExamId ||
        !this.bonusQuestion.originalQuestionId
      ) {
        return this.$snackbar("Chưa chọn câu hỏi hoặc đề gốc", "error");
      }
      if (
        this.bonusQuestions.find(
          (item) =>
            item.originalQuestionId === this.bonusQuestion.originalQuestionId
        )
      )
        return this.$snackbar("Câu hỏi đã được chọn", "error");
      this.bonusQuestions.push(this.bonusQuestion);
      this.bonusQuestion = {
        originalExamId: null,
        originalQuestionId: null,
      };
    },
    async getOriginalExams() {
      const { data } = await getOriginalExams(this.$route.params.id);
      this.originalExams = data;
      console.log(this.originalExams);
    },
    async calculate() {
      this.loading = true;
      await calculate(this.$route.params.id, {
        originalQuestionIds: this.bonusQuestions.map(
          (item) => item.originalQuestionId
        ),
      });
      window.location.reload();
    },
    async getMetadata() {
      const {
        subjects,
        classIds,
        studyGroupNames,
        examination,
      } = await getMetadata(this.$route.params.id);
      this.subjects = subjects;
      this.classIds = classIds;
      this.studyGroupNames = studyGroupNames;
      this.examination = examination;
      if (this.examination.isCalculated === false) {
        setTimeout(() => {
          this.getMetadata();
          this.getResult();
        }, 30 * 1000);
      }
    },
    async getResult(page = null) {
      try {
        this.$loader(true);
        if (page) this.params.page = page;
        const { data, meta } = await getResult(
          this.$route.params.id,
          this.params
        );
        this.result = data;
        this.pagination = meta;
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    },
    exportExcel() {
      const host =
        (process.env.NODE_ENV === "development"
          ? "http://localhost:3333"
          : "") + `/api/examinations/${this.examination.id}/result`;
      let qs = "?export=true";
      for (const key in this.params) {
        if (["page", "perPage"].includes(key)) continue;
        qs += `&${key}=${this.params[key] || ""}`;
      }
      window.open(host + qs);
    },
  },
  created() {
    this.getMetadata();
    this.getResult();
    this.getOriginalExams();
  },
};
</script>
